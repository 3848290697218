
.nav {
	background-color: #1d0404;
  top: 0;
  padding-top: 0px;
  margin-top: 0px;
  width: 100%;
  text-align: center;
  font: 1em 'Montserrat';
  ul {
    list-style: none;

      padding-left: 10px;
      padding-right: 10px;
    li {
      display: inline;
      padding-left: 10px;
      padding-right: 10px;
      a {
        text-decoration: none;
        color: white;
        padding: 10px;

        &.is-active {
              background-color: #BD0034;
              border-bottom: 5px solid rgb(255, 206, 116);
              
        }

        &:hover {border-bottom: 5px solid rgb(255, 206, 116);
              
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {

  .nav {
    background-color: #1d0404;
    top: 0;
    width: 100%;
    text-align: center;
    font: 1em 'Montserrat';
    ul {
      list-style: none;
      
        padding-left: 1px;
        padding-right: 1px;
      li {
        display: inline;
        padding-left: 1px;
        
        a {
          text-decoration: none;
          color: white;
          padding: 2px;
  
          &.is-active {
                background-color: #BD0034;
                border: 0px;
                border-left: 5px solid rgb(255, 206, 116);
                
          }
  
          &:hover {
            border: 0px;
            border-left: 5px solid rgb(255, 206, 116);
                
          }
        }
      }
    }
  }
  
  
}

// .content {
//   text-align: center;

//   .code-block {
//     color: #777777;
//     padding: 1rem;
//     background: #f7f7f7;
//     border-left: 5px solid cadetblue;
//   }

//   #header {
//     height: 400px;
//     padding-top: 100px;
//     background: #f7f7f7;
//   }

//   @mixin section() {
//     height: 500px;
//   }

//   #section_1 {
//     @include section;

//     background: white;
//   }

//   #section_2 {
//     @include section;

//     background: aliceblue;
//     height: 700px;

//     .code-block {
//       width: fit-content;
//     }
//   }

//   #section_3 {
//     @include section;

//     table {
//       border-collapse: collapse;

//       thead tr th {
//         border-bottom: 4px solid cadetblue;
//       }

//       tbody {
//         color: #777777;
//       }

//       th,
//       td {
//         padding: 0.5rem;
//       }
//     }
//   }

//   .section-content {
//     padding: 5rem 5rem 5rem 5rem;
//     text-align: left;
//   }
// }
