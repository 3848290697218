@import url(https://fonts.googleapis.com/css?family=Montserrat);

body {
    width: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-y: auto;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.styledcard {
    box-shadow: -1px 10px 29px 0px rgba(0, 0, 0, 0.8);
    border-radius: 0px;

}

.styledcard:hover {
    box-shadow: -1px 10px 29px 0px rgba(0, 0, 0, 0.8);
}

.smaller {
    height: 0px;


    transition: 0.5s;
    /* Adds a transition effect when the padding is decreased */
}

.mygrid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.mygrid-container>div {
    text-align: center;
    justify-content: end;
    align-self: end;
}


#svglogo {}

.introholder {
    position: relative;
}

.gumframe {
    width: 500px;
    height: 300px;
    position: relative;
    left: 0%;
}

.contactinfo {
    position: absolute;
    left: 45%;
    top: 0px;
    color: #444444;
    font: 15px 'Montserrat';
}

svg {
    font-size: 5em;
    font-family: 'Share Tech Mono', monospace;
    background-color: #1d0404;
    display: inline;
    /* the default for span */
    transition: 0.5s;
    /* Adds a transition effect when the padding is decreased */
    padding: 0px;
    margin: 2px;
}

@media only screen and (max-width: 600px) {


    svg {
        height: 0px;
    }


    .introholder {
        position: relative;
    }

    .gumframe {
        width: 200px;
        position: relative;
        left: 0%;
    }

    .contactinfo {
        position: relative;
        top: 0px;
        color: #444444;
        font: 15px 'Montserrat';
    }

}

.text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 3px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 6.5s infinite linear;
}

.text-copy:nth-child(1) {
    stroke: #ff2656;
    animation-delay: -1;
}

.text-copy:nth-child(2) {
    stroke: #ee1646;
    animation-delay: -2s;
}

.text-copy:nth-child(3) {
    stroke: #ff2656;
    animation-delay: -3s;
}

.text-copy:nth-child(4) {
    stroke: #ff3666;
    animation-delay: -4s;
}

.text-copy:nth-child(5) {
    stroke: rgb(255, 206, 116);
    animation-delay: -5s;
}

.text-copy:nth-child(6) {
    stroke: #BD0034;
    animation-delay: -6s;
}

@keyframes stroke-offset {
    100% {
        stroke-dashoffset: -35%;
    }
}



.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    line-height: 2;
}


a {
    text-decoration: none;
    color: #BD0034;
}

a:hover {
    color: rgb(255, 206, 116);
}


ul {
    list-style-type: square;
    padding-bottom: 0;
}


main {
    background-color: #ffffff;
    padding: 0px;
    margin: 0px;
}
